import { useState, useEffect, useRef, Fragment } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import { history } from "../../helpers/history";
import BoLoader from "../../helpers/BoLoader";
import DialoguePopup from "../../helpers/DialoguePopup";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import PhoneInput from "react-phone-input-2";
import RefreshToken from "../../helpers/RefreshToken";
import ReactFlagsSelect from "react-flags-select";
import ModelPopup from "../../helpers/ModelPopup";
import { Link } from "react-router-dom";
import { useColor, ColorPicker } from "react-color-palette";
import PreviewTheme from "./PreviewTheme";
import identityverificationObj from "../../services/identityverification.service";
import crossimg from "../../assets/images/icons/cross.svg";
import * as EmailValidator from "email-validator";
import { TrimCharacters } from "../../helpers/TrimCharacters";

let obj = [];
var fontStyle = {
  textTransform: "uppercase",
};
const checkValidEail = emailAdress => {
  let value = EmailValidator.validate(emailAdress);
  return value;
};
const required = value => {
  if (!value) {
    return <span className="help-block">this field is required!</span>;
  }
};
const AddressRequired = (value) => {
  if (!value) {
    document.getElementById("id_addressClient").classList.add("has_error");
    return <span className="help-block">Address is required!</span>;
  } else
    document.getElementById("id_addressClient").classList.remove("has_error");
};
const CityRequired = (value) => {
  if (!value) {
    document.getElementById("id_cityClient").classList.add("has_error");
    return <span className="help-block">City is required!</span>;
  } else document.getElementById("id_cityClient").classList.remove("has_error");
};
const ZipCodeRequired = (value) => {
  if (!value) {
    document.getElementById("id_zipCodeClient").classList.add("has_error");
    return <span className="help-block">Zip Code is required!</span>;
  } else
    document.getElementById("id_zipCodeClient").classList.remove("has_error");
};
const ClientProfile = (props) => {
  const requiredNationality = (value) => {
    if (!value) {
      document.getElementById("id_nationality").classList.add("has_error");
      setshowNationalityErr("");
      return false;
    } else {
      document.getElementById("id_nationality").classList.remove("has_error");
      setshowNationalityErr("d-none");
      return true;
    }
  };
  const CompanyNameRequired = (value) => {
    if (!value) {
      document.getElementById("companyName").classList.add("has_error");
      return <span className="help-block">Company Name is required!</span>;
    } else document.getElementById("companyName").classList.remove("has_error");
  };
  const CheckNegativePrefund = (value) => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document
        .getElementById("id_prefundamountClient")
        .classList.add("has_error");
      return (
        <span className="help-block">Prefund Amount cannot be negative!</span>
      );
    } else
      document
        .getElementById("id_prefundamountClient")
        .classList.remove("has_error");
  };
  const ReferenceEmailFormatRequired = value => {
    if (value) {
      let verifyemailcheck = checkValidEail(value);
      if (!verifyemailcheck) {
        document.getElementById("reference_email").classList.add("has_error");
        return <span className="help-block">Please enter valid email!</span>;
      } else document.getElementById("reference_email").classList.remove("has_error");
    }
  };
  const cardLimitRequired = value => {
    if (!value) {
      document.getElementById("id_cardLimit").classList.add("has_error");
      return <span className="help-block">Card Limit is required!</span>;
    } else
      document.getElementById("id_cardLimit").classList.remove("has_error");
  };
  const checkCardLimitNegative = value => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_cardLimit").classList.add("has_error");
      return <span className="help-block">Card Limit cannot be negative!</span>;
    } else
      document.getElementById("id_cardLimit").classList.remove("has_error");
  };

  const EmailRequired = value => {
    if (!value) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Email is required!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const EmailFormatRequired = value => {
    let verifyemailcheck = checkValidEail(value);
    if (!verifyemailcheck) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Please enter valid email!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
                        
  const [clientName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [RefrenceEmail, setRefrenceEmail] = useState("");
  const [currency, setCurrency] = useState("");
  const [provider, setProvider] = useState("");
  const [issuerCatagory, setissuerCatagory] = useState("");
  const [Address, setAddress] = useState("");
  const [Mobile, setPhoneNumber] = useState("");
  const [pointOfContact, setPointOfContract] = useState("");
  const [PrefundAmount, setPrefundedAmount] = useState();
  const [clientStatusId, setclientStatusId] = useState();
  const [logoUrl, setLogoUrl] = useState("");
  const [LogoName, setlogoName] = useState("");
  const [StatusList, setStatusList] = useState([]);
  const [id, setid] = useState("101");
  const [clientId, setClientId] = useState(props?.clientId);
  const [isValidPhoneNumber, setisValidPhoneNumber] = useState(true);
  const [userId, setuserId] = useState(props.userId);
  const [Status, setStatus] = useState(props.clientStatus);
  const [accountType, setAccountType] = useState();
  const [LogoSize, setlogoSize] = useState();
  const [isValidFile, setisValidFile] = useState(true);
  const [Logourl, setlogoUrl] = useState("");
  const [balance, setBalance] = useState();

  //Loader
  const [boloader, setBoLoader] = useState(false);
  //Error/Successfull popup showings
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  //UseRef
  const form = useRef();
  const checkBtn = useRef();
  //Nium API Fields
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [showNationalityErr, setshowNationalityErr] = useState("d-none");
  const [showPhoneErr, setshowPhoneErr] = useState("d-none");
  //Modals
  const [modaldeactivateshow, setModaldeactivateshow] = useState(false);
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [modalsuccesssave, setModalsuccesssave] = useState(false);

  //Theming palate selection
  const [modalsuccessfulltheme, setModalsuccesstheme] = useState(false);
  const [modalPreviewShow, setModalPreviewShow] = useState(false);
  //Theming Colour Selection Codes
  const [PrimaryColorCode, setPrimaryColorCode] = useColor("hex", "");
  const [SecondaryColorCode, setSecondaryColorCode] = useColor(
    "hex",
    ""
  );
  const [colorlight1, setColorlight1] = useColor("hex", "");
  const [colorlight2, setColorlight2] = useColor("hex", "");
  const [MainBackgroundColor, setMainBackgroundColor] = useColor("hex", "");
const [MenuBackgroundColor, setMenuBackgroundColor] = useColor("hex", "");
const [CardBackgroundColor, setCardBackgroundColor] = useColor("hex", "");
const [SelectedTabColor, setSelectedTabColor] = useColor("hex", "");
const [TabHoverColor, setTabHoverColor] = useColor("hex", "");
const [HeadingTextColor, setHeadingTextColor] = useColor("hex", "");
const [IconColor, setIconColor] = useColor("hex", "");
const [LineColor, setLineColor] = useColor("hex", "");
const [TextBoxColor, setTextBoxColor] = useColor("hex", "");
const [FontColor, setFontColor] = useColor("hex", "");
  const [colortype, setColortype] = useState();
  //Permissions
  const [Permissions, setPermissions] = useState([]);
  const [PermissionsList, setPermissionsList] = useState([]);
  //Set Card Limit
  const [CardLimit, setCardLimit] = useState();
  const [cardsInInventory, setCardsInInventory] = useState();
  const [CardVisaMaster, setCardVisaMaster] = useState();
  const [CardNetworks, setCardNetworks] = useState({Both:"Both",Visa:"Visa",Master:"Master"});
  const [message, setMessage] = useState();
  const [calledfrom, setCalledfrom] = useState();
  useEffect(() => {
    setisValidFile(true);
  }, [setlogoSize]);
  const pointOfContactRequired = (value) => {
    if (!value) {
      document.getElementById("id_pointofcontact").classList.add("has_error");
      return <span className="help-block">Point Of Contact is required!</span>;
    } else
      document
        .getElementById("id_pointofcontact")
        .classList.remove("has_error");
  };
  const checkPhoneLength = (mobile) => {
    if (mobile.length === 0) {
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneErr("");
      return false
    } else if (mobile.length < 3) {

      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneErr("");
      return false;
    } else {

      document.getElementById("id_phonenumber").classList.remove("has_error");
      setshowPhoneErr("d-none");
      return true
    }
  }
  const onChangeReferenceEmail = e => {
    const Email = e.target.value;
    setRefrenceEmail(Email);

  };
  useEffect(() => {
    setTimeout(() => {
      setClientId(props.clientId);
      setuserId(props.userId);
      setStatus(props.clientStatus);
    }, 2000);
    clientmanagmentObj
      .getStatuses()
      .then((response) => {
        if (response && response.data && response.data.payload) {
          const lst_statuses = response.data.payload.result.map((data) => {
            return {
              value: data.id,
              label: data.name,
            };
          });
          setStatusList(lst_statuses);
        }
      })
      .catch((error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setClientId(props.clientId);
    }, 2000);
    clientdetails();
  }, []);
  useEffect(() => {
    if (Mobile.length === 0) {
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneErr("");
    } else if (Mobile.length < 3) {
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneErr("");
    } else {
      document.getElementById("id_phonenumber").classList.remove("has_error");
      setshowPhoneErr("d-none");
    }
  }, [Mobile]);
  const clientdetails = () => {
    setBoLoader(true);
    clientmanagmentObj
      .getprofiledetails(clientId)
      .then((response) => {
        if (response && response.data && response.data.message === "Success") {
          setBoLoader(false);
          let res = response.data.payload.result;
          if (res) {
            setName(res.clientName);
            setEmail(res.email);
            setAddress(res.address);
            setPointOfContract(res.pointOfContact);
            setCountryCode(res.countryCode);
            setAddress(res.address);
            setCity(res.city);
            setZipCode(res.zipCode);
            let a = { hex: res.primaryColorCode, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, }, };
            let b = { hex: res.secondaryColorCode, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, }, };
            let c = { hex: res.colorPrimarylighter1, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, }, };
            let d = { hex: res.colorPrimarylighter2, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, }, };
            let mainBg = { hex: res.mainBackgroundColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let menuBg = { hex: res.menuBackgroundColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let cardBg = { hex: res.cardBackgroundColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let selectedTab = { hex: res.selectedTabColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let tabHover = { hex: res.tabHoverColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let headingText = { hex: res.headingTextColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let icon = { hex: res.iconColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let line = { hex: res.lineColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let textBox = { hex: res.textBoxColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };
            let font = { hex: res.fontColor, hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 }, rgb: { a: undefined, b: 21, g: 21, r: 21, } };

            setPrimaryColorCode(a);
            setSecondaryColorCode(b);
            setColorlight1(c);
            setColorlight2(d);
            setMainBackgroundColor(mainBg);
            setMenuBackgroundColor(menuBg);
            setCardBackgroundColor(cardBg);
            setSelectedTabColor(selectedTab);
            setTabHoverColor(tabHover);
            setHeadingTextColor(headingText);
            setIconColor(icon);
            setLineColor(line);
            setTextBoxColor(textBox);
            setFontColor(font);
            if (res.mobile) setPhoneNumber(res.mobile);
            if (res.prefundAmount) {
              let value = parseFloat(res.prefundAmount);
              setPrefundedAmount(value);
            }
            if (res.clientStatusId) {
              let statusvalue = parseInt(res.clientStatusId);
              setclientStatusId(statusvalue);
            }
            setid(res.id);
            setLogoUrl(res.logoUrl);
            setBalance(res.balance);
            props.balance(res.balance);
            const lst_permissions = res.permissions.map(data => {
              return {
                UserId: String(data.id),
                ClaimType: data.claimType,
                ClaimValue: data.claimValue,
                checked: data.isAssigned,
              };
            });
            setPermissionsList(lst_permissions);
            const assign_perm = lst_permissions.filter(
              i => i.checked === true
            );
            setPermissions(assign_perm);
            setCurrency(res.currencyCode);
            setProvider(res.paymentProcesor);
            setissuerCatagory(res.issuerCategory)
            setRefrenceEmail(res.refrenceEmail);
            setCardLimit(res?.cardLimitTotal);
            setCardsInInventory(res?.cardLimitRemaining);
            setAccountType(res?.accountType)
            setCardVisaMaster(res?.cardVisaMaster)
          }
        }
      })
      .catch((error) => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            clientdetails();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      });
  };

  const onChangename = (e) => {
    const ClientName = e.target.value;
    setName(ClientName);
  };
  const onChangeemail = (e) => {
    const Email = e.target.value;
    setEmail(Email);
  };
  const onChangepointOfContact = (e) => {
    let value = (e.target.value)
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setPointOfContract(value);

  };
  // const onChangephoneNumber = (e) => {
  //   const PhoneNumber = e.target.value;
  //   setPhoneNumber(PhoneNumber);
  // };
  const onChangeaddress = (e) => {
    const Address = e.target.value;
    setAddress(Address);
  };
  const onChangestatus = (e) => {
    const status = e.target.selectedOptions[0].value;
    if (status != "") {
      let statusvalue = parseInt(status);
      setclientStatusId(statusvalue);
    }
  };
  const onChangePrefundedAmount = (e) => {
    const PrefundAmount = e.target.value;
    if (PrefundAmount !== "") {
      let value = parseFloat(PrefundAmount);
      setPrefundedAmount(value);
    }
  };
  //UpdateUser
  const saveUser = (e) => {
    // e.preventDefault();
    form.current.validateAll();
    let nationality = requiredNationality(CountryCode);
    let mobile = checkPhoneLength(Mobile);
    if (checkBtn.current.context._errors.length === 0 && nationality && mobile) {

      setBoLoader(true);
      clientmanagmentObj
        .updateclient(
          id,
          clientName,
          email,
          Address,
          Mobile,
          pointOfContact,
          PrefundAmount,
          clientStatusId,
          City,
          ZipCode,
          CountryCode,
          PrimaryColorCode.hex ? PrimaryColorCode.hex : PrimaryColorCode,
          SecondaryColorCode.hex ? SecondaryColorCode.hex : SecondaryColorCode,
          colorlight1.hex ? colorlight1.hex : colorlight1,
          colorlight2.hex ? colorlight2.hex : colorlight2,
          MainBackgroundColor.hex ? MainBackgroundColor.hex : MainBackgroundColor,
          MenuBackgroundColor.hex ? MenuBackgroundColor.hex : MenuBackgroundColor,
          CardBackgroundColor.hex ? CardBackgroundColor.hex : CardBackgroundColor,
          SelectedTabColor.hex ? SelectedTabColor.hex : SelectedTabColor,
          TabHoverColor.hex ? TabHoverColor.hex : TabHoverColor,
          HeadingTextColor.hex ? HeadingTextColor.hex : HeadingTextColor,
          IconColor.hex ? IconColor.hex : IconColor,
          LineColor.hex ? LineColor.hex : LineColor,
          TextBoxColor.hex ? TextBoxColor.hex : TextBoxColor,
          FontColor.hex ? FontColor.hex : FontColor,
          logoUrl, Permissions, RefrenceEmail, CardLimit,CardVisaMaster
        )
        .then((response) => {
          setBoLoader(false);
          if (response && response.data && !response.data.isError) {
            setid("");
            setName("");
            setEmail("");
            setAddress("");
            setPointOfContract("");
            setPhoneNumber("");
            setPrefundedAmount();
            setclientStatusId();
            // history.goBack();
            setModalsuccesssave(true);
            //history.push("clients");
          }
        })
        .catch((err) => {
          setBoLoader(false);
          let msgBody = err?.response?.data?.message
            ? err?.response?.data?.message
            : "Error Occured while Registering client";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onCancel = () => {
    history.goBack();
    //history.push("clients");
  };
  const onChangeCity = (e) => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z ]/ig, '')
    setCity(value);
  };
  const onChangeZipCode = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')

    setZipCode(value);
  };
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const onSuspendClient = () => {
    setBoLoader(true);
    clientmanagmentObj
      .suspendClient(userId,"")
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.payload) {
          setModaldeactivateshow(false);
          setModalsuccessfullShow(true);
        }
      })
      .catch((err) => {
        setBoLoader(false);
        setModaldeactivateshow(false);
      });
  };
  const onUnsuspendClient = () => {
    setBoLoader(true);
    clientmanagmentObj
      .unSuspendClient(userId,"")
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.payload) {
          setModaldeactivateshow(false);
          setModalsuccessfullShow(true);
        }
      })
      .catch((err) => {
        setBoLoader(false);
        setModaldeactivateshow(false);
      });
  };
  const onClosedClient = () => {
    setBoLoader(true);
    clientmanagmentObj
      .unSuspendClient(userId,"closed")
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && res.data.payload) {
          setModaldeactivateshow(false);
          setModalsuccessfullShow(true);
        }
      })
      .catch((err) => {
        setBoLoader(false);
        setModaldeactivateshow(false);
      });
  };
  const openModal = () => {
    setModaldeactivateshow(true);
  };
  const openModalClosed = () => {
    setCalledfrom("fromclosed");
    setModaldeactivateshow(true);
  };
  const goToClients = () => {
    clientdetails();
  };

  const previewTheme = (e) => {
    e?.preventDefault();
    let localhostUrl = "http://localhost:3003/previewtheme";
    let deployedUrl = "https://dev.card-console.com/previewtheme";
    let stagingUrl = "https://card-console.com/previewtheme";

    let url = window.location.href;
    if (url.includes("localhost")) url = localhostUrl;
    else if (url.includes("dev")) url = deployedUrl;
    else
      url = stagingUrl;
    let pri = PrimaryColorCode.hex ? PrimaryColorCode.hex : PrimaryColorCode;
    if (pri.includes("#"))
      pri = pri.substring(1);
    let sec = SecondaryColorCode.hex ? SecondaryColorCode.hex : SecondaryColorCode
    if (sec.includes("#"))
      sec = sec.substring(1);
    let lcf = colorlight1.hex ? colorlight1.hex : colorlight1;
    if (lcf.includes("#"))
      lcf = lcf.substring(1);
    let lcs = colorlight2.hex ? colorlight2.hex : colorlight2;
    if (lcs.includes("#"))
      lcs = lcs.substring(1);
    let mainBg = MainBackgroundColor.hex ? MainBackgroundColor.hex : "#f8f4f4";
    if (mainBg?.includes("#"))
      mainBg = mainBg.substring(1);
    let menuBg = MenuBackgroundColor.hex ? MenuBackgroundColor.hex : "#ffffff";
    if (menuBg?.includes("#"))
      menuBg = menuBg.substring(1);
    let cardBg = CardBackgroundColor.hex ? CardBackgroundColor.hex : "#161618";
    if (cardBg?.includes("#"))
      cardBg = cardBg.substring(1);
    let selectedTab = SelectedTabColor.hex ? SelectedTabColor.hex : "#ffffff";
    if (selectedTab?.includes("#"))
      selectedTab = selectedTab.substring(1);
    let tabHover = TabHoverColor.hex ? TabHoverColor.hex : "#ffffff";
    if (tabHover?.includes("#"))
      tabHover = tabHover.substring(1);
    let headingText = HeadingTextColor.hex ? HeadingTextColor.hex : "#121212";
    if (headingText?.includes("#"))
      headingText = headingText.substring(1);
    let icon = IconColor.hex ? IconColor.hex : "#ffffff";
    if (icon?.includes("#"))
      icon = icon.substring(1);
    let line = LineColor.hex ? LineColor.hex : "#161618";
    if (line?.includes("#"))
      line = line.substring(1);
    let textBox = TextBoxColor.hex ? TextBoxColor.hex : "#161618";
    if (textBox?.includes("#"))
      textBox = textBox.substring(1);
    let font = FontColor.hex ? FontColor.hex : "#FAFAFA";
    if (font?.includes("#"))
      font = font.substring(1);
    window
      .open(
        url +
        "?pri=" +
        pri +
        "&sec=" +
        sec + "&lcf=" + lcf + "&lcs=" + lcs+ 
        "&mainBg=" + mainBg+ "&menuBg=" + menuBg+ "&cardBg=" + cardBg+ "&selectedTab=" + selectedTab+ "&tabHover=" + tabHover
        + "&headingText=" + headingText+ "&icon=" + icon+ "&line=" + line+ "&textBox=" + textBox+ "&font=" + font
      )
      .focus();
    //setModalPreviewShow(true);
  };
  const openModalcolor = ( type) => {
    let e = {
      hex: "#151515",
      hsv: { a: undefined, h: 0, s: 0, v: 8.105263960988893 },
      rgb: {
        a: undefined,
        b: 21,
        g: 21,
        r: 21,
      },
    };
    if (type === "pri") setPrimaryColorCode(e);
    else if (type === "sec") setSecondaryColorCode(e);
    else if (type === "lc1") setColorlight1(e);
    else if (type === "lc2") setColorlight2(e);
    else if (type === "mainBg") setMainBackgroundColor(e);
    else if (type === "menuBg") setMenuBackgroundColor(e);
    else if (type === "cardBg") setCardBackgroundColor(e);
    else if (type === "selectedTab") setSelectedTabColor(e);
    else if (type === "tabHover") setTabHoverColor(e);
    else if (type === "headingText") setHeadingTextColor(e);
    else if (type === "icon") setIconColor(e);
    else if (type === "line") setLineColor(e);
    else if (type === "textBox") setTextBoxColor(e);
    else if (type === "font") setFontColor(e);

    setColortype(type);
    setModalsuccesstheme(true);
  };
  const onChangePrimaryColor = e => {
    const color = e.target.value;
    setPrimaryColorCode(color);
  };
  const onChangeSecondaryColor = e => {
    const color = e.target.value;
    setSecondaryColorCode(color);
  };
  const onChangeLightColor1 = e => {
    const color = e.target.value;
    setColorlight1(color);
  };
  const onChangeLightColor2 = e => {
    const color = e.target.value;
    setColorlight2(color);
  };
  const onChangeMainBackgroundColor = e => {
    const color = e.target.value;
    setMainBackgroundColor(color);
};

const onChangeMenuBackgroundColor = e => {
    const color = e.target.value;
    setMenuBackgroundColor(color);
};

const onChangeCardBackgroundColor = e => {
    const color = e.target.value;
    setCardBackgroundColor(color);
};

const onChangeSelectedTabColor = e => {
    const color = e.target.value;
    setSelectedTabColor(color);
};

const onChangeTabHoverColor = e => {
    const color = e.target.value;
    setTabHoverColor(color);
};

const onChangeHeadingTextColor = e => {
    const color = e.target.value;
    setHeadingTextColor(color);
};

const onChangeIconColor = e => {
    const color = e.target.value;
    setIconColor(color);
};

const onChangeLineColor = e => {
    const color = e.target.value;
    setLineColor(color);
};

const onChangeTextBoxColor = e => {
    const color = e.target.value;
    setTextBoxColor(color);
};

const onChangeFontColor = e => {
    const color = e.target.value;
    setFontColor(color);
};
  const closeModalcolor = () => {
    console.log(colorlight1);
  };
  const uploadFileAddClient = e => {
    document.getElementById("UpdateClientFileUpload").click();
  };
  const onChangeLogo = e => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    let reg = /(.*?)\.(jpg|bmp|jpeg|PNG|png)$/;
    if (!file_data.name.match(reg)) {
      setisValidFile(false);
      return false;
    }
    setlogoName(file_data.name);
    var size = formatBytes(file_data.size);
    setlogoSize(size);
    formdata.append("File", file_data);
    identityverificationObj
      .uploaduserdocs(formdata)
      .then(response => {
        let name = response.data.payload;
        setLogoUrl(name);
      })
      .catch(error => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  };
  const onCrossImg = () => {
    setlogoName("");
    setisValidFile(true);
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const handleSingleCheck = e => {
    obj = Permissions
    if (e.target.checked === true) {
      obj = obj.concat({
        UserId: e.target.id,
        ClaimType: e.target.attributes.claimtype.value,
        ClaimValue: e.target.attributes.claimvalue.value,
      });
      setPermissions(obj);
      const index = PermissionsList.findIndex((ch) => String(ch.UserId) === e.target.id);
      PermissionsList[index].checked = e.target.checked
    }
    if (e.target.checked === false) {
      obj = Permissions.filter(
        i => i.ClaimType !== e.target.attributes.claimtype.value
      );
      setPermissions(obj);
      const index = PermissionsList.findIndex((ch) => String(ch.UserId) === e.target.id);
      PermissionsList[index].checked = e.target.checked;
    }
  };
  const onChangeCardLimit = e => {
    const CardLimit = e.target.value;
    if (CardLimit != "") {
      let value = parseFloat(CardLimit);
      setCardLimit(value);
    }
  };
  const onChangeCardVisaMaster = (e) => {
    const name = e?.target?.selectedOptions[0]?.value;
    setCardVisaMaster(name);
  }
  return (
    <div
      className="tab-pane fade show active"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-6">
          <div className="logo-large">
            {logoUrl !== "" ? (
              <img src={logoUrl} alt="clientProfile" />
            ) : (
              <span className="td-logo">
                <h1 style={fontStyle}>{clientName.charAt(0)}</h1>
              </span>
            )}
          </div>
        </div>
        {/* <div className="col-md-3 col-xl-3">
          <div
            className="col-stats col-stats-card mb-40"
            style={{ backgroundImage: `url(${""})`, height:`12rem` }}
          >
            <h3>Ledger Balance: {balance} </h3>
          
            <span className="icon-Wallet"></span>
          
          </div>
        </div> */}
       
        

        <div className="col-md-8">
          <div>
            <Form onSubmit={(e) => { e.preventDefault(); }} ref={form}>
              <div className="row mt-40">
                <div className="col-md-6"  id="companyName">
                  <div className="form-group">
                    <label>Client Name *</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        id="companyName"
                        onChange={onChangename}
                        value={clientName}
                        title={clientName}
                        validations={[CompanyNameRequired]}
                        //disabled={true}
                      />
                      <span className="icon-company _ico"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"  id="email">
                  <div className="form-group">
                    <label>Email *</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeemail}
                        title={email}
                        name="email"
                        validations={[
                          EmailRequired,
                          required,
                          EmailFormatRequired,
                        ]}
                        value={email}
                        //disabled={true}
                      />
                      <span className="icon-email _ico"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Currency</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        value={currency}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-controls"></div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Provider</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        value={provider==="MSwipe"?"BO":"-"}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-controls"></div>
                </div>


                {provider ==="MSwipe" && <div className="col-md-6">
                  <div className="form-group">
                    <label>Issuer Catagory</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        value={issuerCatagory}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-controls"></div>
                </div>}
                {
                   (issuerCatagory==="IssuerS" || issuerCatagory==="IssuerB") && currency === "USD" && (
                    <Fragment>
                       <div className="col-md-6" id="id_networks">
                       <div className="form-group">
                    <label>Network</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        value={CardVisaMaster}
                        disabled={true}
                      />
                    </div>
                  </div>
                  </div>
                  <div className="col-md-6" id="id_network">
                      <div className="form-group">
                        <label>Update Network</label>
                        <div className="form-controls">
                          <select
                            onChange={onChangeCardVisaMaster}
                            className="form-control no-icon"
                          >
                             <option  value={CardNetworks?.Both}>
                              Both
                            </option>
                           
                            <option value={CardNetworks?.Visa}>
                              Visa
                            </option>
                            <option select="selected" value={CardNetworks?.Master}>
                              MC
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    </Fragment>
                    
                  )
                }
                <div className="col-md-6" id="id_phonenumber">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <div className="form-controls">
                      <PhoneInput
                        // onlyCountries={[
                        //   "gb",
                        //   "ac",
                        //   "be",
                        //   "bg",
                        //   "cy",
                        //   "cz",
                        //   "de",
                        //   "dk",
                        //   "ee",
                        //   "es",
                        //   "fi",
                        //   "fr",
                        //   "gr",
                        //   "hr",
                        //   "hu",
                        //   "ie",
                        //   "it",
                        //   "lt",
                        //   "lu",
                        //   "lv",
                        //   "mt",
                        //   "nl",
                        //   "po",
                        //   "pt",
                        //   "ro",
                        //   "se",
                        //   "si",
                        //   "sk",
                        // ]}
                        className=""
                        country={"gb"}
                        onChange={setPhoneNumber}
                        value={Mobile}
                      />
                      {/* <span className="icon-phone _ico"></span> */}


                    </div>
                    <span className={"help-block " + showPhoneErr}>
                      Minimum three number are required!
                    </span>
                  </div>
                </div>
                <div className="col-md-6" id="id_pointofcontact">
                  <div className="form-group">
                    <label>Point of Contact *</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangepointOfContact}
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }
                        }
                        value={pointOfContact}
                        maxLength="20"
                        validations={[pointOfContactRequired]}
                      />
                      <span className="icon-user _ico"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group" id="id_nationality">
                    <label>Nationality*</label>
                    <div className="form-controls">
                      <ReactFlagsSelect
                        // countries={[
                        //   "GB",
                        //   "AT",
                        //   "BE",
                        //   "BG",
                        //   "CY",
                        //   "CZ",
                        //   "DE",
                        //   "DK",
                        //   "EE",
                        //   "ES",
                        //   "FI",
                        //   "FR",
                        //   "GR",
                        //   "HR",
                        //   "HU",
                        //   "IE",
                        //   "IT",
                        //   "LT",
                        //   "LU",
                        //   "LV",
                        //   "MT",
                        //   "NL",
                        //   "PO",
                        //   "PT",
                        //   "RO",
                        //   "SE",
                        //   "SI",
                        //   "SK",
                        // ]}
                        className="form-control no-icon"
                        selected={CountryCode}
                        onSelect={(code) => setCountryCode(code)}
                        searchable={true}
                      />
                      <span className={"help-block " + showNationalityErr}>
                        Nationality is required!
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Status</label>
                    <div className="form-controls">
                      {/* <select
                        className="form-control no-icon"
                        onChange={onChangestatus}
                        value={clientStatusId}
                      >
                        {StatusList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select> */}
                      <Input
                        type="text"
                        className="form-control no-icon"
                        value={Status}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-controls"></div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Account Type</label>
                    <div className="form-controls">
                     
                      <Input
                        type="text"
                        className="form-control no-icon"
                        value={accountType}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-controls"></div>
                </div>
                <div className="col-md-12" id="id_addressClient">
                  <div className="form-group">
                    <label>Address*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control left-icon"
                        onChange={onChangeaddress}
                        value={Address}
                        validations={[AddressRequired]}
                        maxLength="40"
                      />
                      <span className="icon-home _ico"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_cityClient">
                  <div className="form-group">
                    <label>City*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        onChange={onChangeCity}
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkAlpha(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }

                        }
                        value={City}
                        validations={[CityRequired]}
                        maxLength="20"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_zipCodeClient">
                  <div className="form-group">
                    <label>ZIP Code*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        onChange={onChangeZipCode}
                        onKeyDown={(evt) => {
                          let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                          if (chck)
                            evt.preventDefault();
                        }
                        }
                        value={ZipCode}
                        validations={[ZipCodeRequired]}
                        maxLength="10"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_primColor">
                  <div className="form-group">
                    <label>Primary Colour*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        value={PrimaryColorCode.hex}
                        onChange={onChangePrimaryColor}
                        //validations={[primaryColorRequired]}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={(e) => openModalcolor(e, "pri")}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_secColor">
                  <div className="form-group">
                    <label>Secondary Colour*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeSecondaryColor}
                        value={SecondaryColorCode.hex}
                        // validations={[secondaryColorRequired]}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={(e) => openModalcolor(e, "sec")}
                      ></span>
                    </div>




                  </div>
                </div>
                <div className="col-md-6" id="id_lightColor1">
                  <div className="form-group">
                    <label>Lighter Colour 1*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control "
                        onChange={onChangeLightColor1}
                        value={colorlight1.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={(e) => openModalcolor(e, "lc1")}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_lightColor2">
                  <div className="form-group">
                    <label>Lighter Colour 2*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control "
                        onChange={onChangeLightColor2}
                        value={colorlight2.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={(e) => openModalcolor(e, "lc2")}
                      ></span>
                    </div>
                  </div>
                  {/* <div >
                    <button type="button" className="border-1 mb-3 mt--1 rounded-2" onClick={previewTheme}>Preview Theme</button>
                  </div> */}
                </div>
                <div className="col-md-6" id="id_MainBackgroundColor">
                  <div className="form-group">
                    <label>Main Background Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeMainBackgroundColor}
                        value={MainBackgroundColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("mainBg")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_MenuBackgroundColor">
                  <div className="form-group">
                    <label>Menu Background Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeMenuBackgroundColor}
                        value={MenuBackgroundColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("menuBg")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_CardBackgroundColor">
                  <div className="form-group">
                    <label>Card Background Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeCardBackgroundColor}
                        value={CardBackgroundColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("cardBg")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_SelectedTabColor">
                  <div className="form-group">
                    <label>Selected Tab Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeSelectedTabColor}
                        value={SelectedTabColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("selectedTab")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_TabHoverColor">
                  <div className="form-group">
                    <label>Tab Hover Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeTabHoverColor}
                        value={TabHoverColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("tabHover")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_HeadingTextColor">
                  <div className="form-group">
                    <label>Heading Text Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeHeadingTextColor}
                        value={HeadingTextColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("headingText")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_IconColor">
                  <div className="form-group">
                    <label>Icon Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeIconColor}
                        value={IconColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("icon")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_LineColor">
                  <div className="form-group">
                    <label>Line Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeLineColor}
                        value={LineColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("line")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_TextBoxColor">
                  <div className="form-group">
                    <label>Text Box Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeTextBoxColor}
                        value={TextBoxColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("textBox")}
                      ></span>
                    </div>
                  </div>
                  {/* <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div> */}
                </div>

                <div className="col-md-6" id="id_FontColor">
                  <div className="form-group">
                    <label>Font Color*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control"
                        onChange={onChangeFontColor}
                        value={FontColor.hex}
                        maxLength="7"
                      />
                      <span
                        className="icon-search _ico"
                        onClick={() => openModalcolor("font")}
                      ></span>
                    </div>
                  </div>
                  <div>
                    <button type="button" onClick={() => previewTheme()} className="border-1 mb-16 rounded-2">Preview Theme</button>
                  </div>
                </div>
                <div className="col-md-6" id="id_cardLimit">
                  <div className="form-group">
                    <label>Card Inventory*</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        maxLength="10"
                        onInput={maxLengthCheck}
                        className="form-control no-icon"
                        name="cardLimit"
                        // onWheel={(e) => e.target.blur()}
                        value={CardLimit}
                        onChange={onChangeCardLimit}
                        onWheel={ event => event.currentTarget.blur() }
                        validations={[checkCardLimitNegative, cardLimitRequired]}
                      />
                    </div>
                    <span className="fs-12 clr-submarine mt-2 d-block">
                        Cards in Inventory : {cardsInInventory}
                      </span>
                  </div>
                 
                </div>
                {/* <div className="col-md-6" id="id_prefundamountClient">
                  <div className="form-group">
                    <label>Prefund Amount</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        maxLength="5"
                        onWheel={(e) => e.target.blur()}
                        onInput={maxLengthCheck}
                        className="form-control no-icon"
                        value={PrefundAmount}
                        onChange={onChangePrefundedAmount}
                        validations={[CheckNegativePrefund]}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div> */}
                {LogoName === "" ? (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Attach Logo</label>
                      <div className="form-controls">
                        <div>
                          <Input
                            type="file"
                            hidden
                            id="UpdateClientFileUpload"
                            className="form-control"
                            accept=".gif,.jpg,.jpeg,.png"
                            name="logo"
                            onChange={onChangeLogo}
                          />
                          <h5
                            className="form-control file-control"
                            onClick={uploadFileAddClient}
                          >
                            <label>Drag files here to upload or </label> Browse
                            file
                          </h5>
                          {!isValidFile && (
                            <span className="help-block has_error">
                              Invalid file format
                            </span>
                          )}
                        </div>
                        <span className="icon-upload-file _ico">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </span>
                      </div>
                      {LogoName !== "" && (
                        <label className="mt-3 mb-0">{LogoName}</label>
                      )}
                      <span className="fs-12 clr-submarine mt-2 d-block">
                        Supported file types pdf/jpg file.
                        Recommended resolutions size is ( 500 * 500 )
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="bo-file d-flex">
                        <div className="file-name">
                          <span className="icon-upload-file _ico">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                          {/* <img src={fileuploadimg} alt="" /> */}
                          <h5>
                            <span>{LogoName}</span>
                          </h5>
                        </div>
                        <div className="ml-auto file-size" onClick={onCrossImg}>
                          <span>{LogoSize}</span>
                          <img
                            className="cross-icon"
                            src={crossimg}
                            onClick={onCrossImg}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6" id="reference_email">
                  <div className="form-group">
                    <label>Reference Email</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        onChange={onChangeReferenceEmail}
                        validations={[
                          ReferenceEmailFormatRequired,
                        ]}
                        value={RefrenceEmail}
                        disabled={true}
                      />
                    </div>
                    <span className="fs-12 clr-submarine mt-2 d-block">
                      Note: only for clients with custom emails.
                    </span>
                  </div>

                </div>
                <div className="col-md-12" id="permissions">
                  <label>Permissions</label>
                  <br />
                  <br />
                  <br />

                  <ul className="checkbox-block-ul mt--24">
                    {PermissionsList.map(PermissionsList => {
                      if(issuerCatagory==="IssuerB" && (PermissionsList?.ClaimType==="Issue Physical Card" || PermissionsList?.ClaimType==="Activate Physical Card" ))
                      return
                      else if(issuerCatagory==="IssuerG" && (PermissionsList?.ClaimType==="Issue Virtual Card" || PermissionsList?.ClaimType==="User KYC" ))
                      return
                      else if(issuerCatagory==="IssuerS" && (PermissionsList?.ClaimType==="Issue Physical Card" || PermissionsList?.ClaimType==="Activate Physical Card" ))
                      return
                      else if(issuerCatagory==="IssuerSP" && (PermissionsList?.ClaimType==="Issue Virtual Card" ))
                      return
                      return (
                        <li>
                          <div className="bo-checkbox bo-checkbox-round">
                            <input
                              key={PermissionsList?.UserId}
                              onChange={handleSingleCheck}
                              type="checkbox"
                              id={PermissionsList?.UserId}
                              ClaimType={PermissionsList?.ClaimType}
                              ClaimValue={PermissionsList?.ClaimType}
                              checked={PermissionsList?.checked}
                            />
                            <label htmlFor={PermissionsList?.UserId}>
                              {PermissionsList?.ClaimType}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-md-12">
                  <div className="form-group submit-field d-flex mt-16 mb-0">
                    <a className="btn btn-large mr-19" onClick={saveUser}>
                      Save
                    </a>
                    <a className="btn btn-outline" onClick={onCancel}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
          {
          Status !== "closed" &&(
            <Fragment>
          {Status === "Active" && (
          <div  >
            <div className="form-group submit-field d-flex justify-content-end mb-0">
              <a className="btn btn-outline btn-outline-large" onClick={openModal}>
                Suspend Client
              </a>
            </div>
          </div>
        )}
        {Status === "Suspended" && (
          <div  >
            <form>
              <div className="form-group submit-field d-flex justify-content-end mb-0">
                <a className="btn btn-outline btn-outline-large" onClick={openModal}>
                  Unsuspend Client
                </a>
              </div>
            </form>
          </div>
        )}
            </Fragment>
          )
        }
       
        {
           Status !== "closed" &&(
           
            <div  >
            <form>
              <div className="form-group submit-field d-flex justify-content-end mt-16">
                <a className="btn btn-outline btn-outline-large" onClick={openModalClosed}>
                  Close Client
                </a>
              </div>
            </form>
          </div>
         
           )
        }
          </div>
        </div>
       
        
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
      <ModelPopup
        key={13}
        show={modaldeactivateshow}
        onHide={() => {setModaldeactivateshow(false); setCalledfrom("");}}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    {Status === "Suspended" && calledfrom!=="fromclosed" ? (
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Are you sure you want to unsuspend Client?</p>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              <Link
                                to="#"
                                className="btn btn-large"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  setModaldeactivateshow(false);
                                  // localStorage.setItem("activeInactiveSendmoney", "true");
                                  //  setpermission = localStorage.getItem("activeInactiveSendmoney");
                                }}
                              >
                                No
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-outline"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  onUnsuspendClient();
                                  // localStorage.setItem("activeInactiveSendmoney", "false");
                                  // setpermission = localStorage.getItem("activeInactiveSendmoney");
                                  //  document.getElementById("dashboardNavLink").click();
                                }}
                              >
                                Yes
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    ): Status === "Active"&& calledfrom!=="fromclosed" ?( <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Are you sure you want to suspend Client?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setModaldeactivateshow(false);
                                //localStorage.setItem("activeInactiveSendmoney", "false");
                                //setpermission = localStorage.getItem("activeInactiveSendmoney");
                              }}
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                onSuspendClient(false);
                                // setModaldeactivateshow(false);
                                // localStorage.setItem("activeInactiveSendmoney", "true");
                                // setpermission = localStorage.getItem("activeInactiveSendmoney");
                                // document.getElementById("dashboardNavLink").click();
                              }}
                            >
                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>):( <form>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Are you sure you want to close client permanently. This action cannot be revered?</p>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              <Link
                                to="#"
                                className="btn btn-large"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  setModaldeactivateshow(false);
                                  setCalledfrom("");
                                
                                }}
                              >
                                No
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-outline"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  onClosedClient(false);
                                   setModaldeactivateshow(false);
                                   setCalledfrom("");
                                  
                                }}
                              >
                                Yes
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>)}
                   
                    
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={12}
        show={modalsuccessfullShow}
        onHide={() => { setModalsuccessfullShow(false); document.getElementById("clientNavLink").click(); }}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          {Status === "Active" ? (
                            <p>Client Suspended Successfully</p>
                          ) : Status === "Suspended" ?(
                            <p>Client Unsuspended Successfully</p>
                          ):(<p>Client Closed Successfully</p>)}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                document.getElementById("clientNavLink").click();
                              }}
                            >
                              Go To Clients
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={14}
        show={modalsuccessfulltheme}
        onHide={() => setModalsuccesstheme(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            {colortype === "pri" ? (
              <ColorPicker
                width={300}
                height={190}
                color={PrimaryColorCode}
                onChange={setPrimaryColorCode}
                hideHSV
                dark
              />
            ) : colortype === "sec" ? (
              <ColorPicker
                width={300}
                height={190}
                color={SecondaryColorCode}
                onChange={setSecondaryColorCode}
                hideHSV
                dark
              />
            ) : colortype === "lc1" ? (
              <ColorPicker
                width={300}
                height={190}
                color={colorlight1}
                onChange={setColorlight1}
                hideHSV
                dark
              />
            ) : colortype === "lc2" ? (
              <ColorPicker
                width={300}
                height={190}
                color={colorlight2}
                onChange={setColorlight2}
                hideHSV
                dark
              />
            ) :
            colortype === "mainBg" ? (
              <ColorPicker
                width={300}
                height={190}
                color={MainBackgroundColor}
                onChange={setMainBackgroundColor}
                hideHSV
                dark
              />
            ) :
            colortype === "menuBg" ? (
              <ColorPicker
                width={300}
                height={190}
                color={MenuBackgroundColor}
                onChange={setMenuBackgroundColor}
                hideHSV
                dark
              />
            ) :
            colortype === "cardBg" ? (
              <ColorPicker
                width={300}
                height={190}
                color={CardBackgroundColor}
                onChange={setCardBackgroundColor}
                hideHSV
                dark
              />
            ) :
            colortype === "selectedTab" ? (
              <ColorPicker
                width={300}
                height={190}
                color={SelectedTabColor}
                onChange={setSelectedTabColor}
                hideHSV
                dark
              />
            ) :
            colortype === "tabHover" ? (
              <ColorPicker
                width={300}
                height={190}
                color={TabHoverColor}
                onChange={setTabHoverColor}
                hideHSV
                dark
              />
            ) :
            colortype === "headingText" ? (
              <ColorPicker
                width={300}
                height={190}
                color={HeadingTextColor}
                onChange={setHeadingTextColor}
                hideHSV
                dark
              />
            ) :
            colortype === "icon" ? (
              <ColorPicker
                width={300}
                height={190}
                color={IconColor}
                onChange={setIconColor}
                hideHSV
                dark
              />
            ) :
            colortype === "line" ? (
              <ColorPicker
                width={300}
                height={190}
                color={LineColor}
                onChange={setLineColor}
                hideHSV
                dark
              />
            ) :
            colortype === "textBox" ? (
              <ColorPicker
                width={300}
                height={190}
                color={TextBoxColor}
                onChange={setTextBoxColor}
                hideHSV
                dark
              />
            ) :
            colortype === "font" ? (
              <ColorPicker
                width={300}
                height={190}
                color={FontColor}
                onChange={setFontColor}
                hideHSV
                dark
              />
            ) : (
              ""
            )}
            <div className="col-md-12">
              <div className="form-group submit-field mt-40 mb-0">
                <Link
                  to="#"
                  className="btn btn-large w-100"
                  onClick={() => {
                    setModalsuccesstheme(false);
                    closeModalcolor();
                  }}
                >
                  Done
                </Link>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={15}
        show={modalPreviewShow}
        onHide={() => setModalPreviewShow(false)}
        modelheading={""}
        showheader={"false"}
        className=""
        dialogClassName=""
        // backdrop="static"
        // keyboard={false}
        modelbody={[
          <Fragment>
            <PreviewTheme
              primary={PrimaryColorCode.hex}
              secondary={SecondaryColorCode.hex}
              colorlight1={colorlight1.hex}
              colorlight2={colorlight2.hex}
              // colorlight2={MainBackgroundColor.hex}
              // colorlight2={MenuBackgroundColor.hex}
              // colorlight2={CardBackgroundColor.hex}
              // colorlight2={SelectedTabColor.hex}
              // colorlight2={TabHoverColor.hex}
              // colorlight2={HeadingTextColor.hex}
              // colorlight2={IconColor.hex}
              // colorlight2={LineColor.hex}
              // colorlight2={TextBoxColor.hex}
              // colorlight2={FontColor.hex}

            />
            <div className="col-md-12">
              <div className="form-group submit-field mt-40 mb-0">
                <Link
                  to="#"
                  className="btn btn-large w-100"
                  onClick={() => {
                    setModalPreviewShow(false);
                  }}
                >
                  Done
                </Link>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={16}
        show={modalsuccesssave}
        onHide={() => setModalsuccesssave(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Client Updated Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccesssave(false);
                                goToClients();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default ClientProfile;
